<template>
    <div
        class="ProgressWrap"
        :class="[`is-${size}`, `is-${variant}`, { 'show-values': showValues }]"
    >
        <span v-if="showValues" class="Hint"> {{ progressText }}</span>
        <slot></slot>
        <div
            class="ProgressBarWrap"
            v-tooltip="
                showValues
                    ? {
                          triggers: ['click'],
                          autoHide: true,
                          distance: 12,
                          content: `${percentComputed}% Von ${formatter(target)}`,
                      }
                    : null
            "
        >
            <div class=" ProgressFull" />
            <div
                class="Progress"
                :class="
                    percentComputed < 25
                        ? 'is-low'
                        : percentComputed < 50
                        ? 'is-medium'
                        : percentComputed < 70
                        ? 'is-high'
                        : 'is-full'
                "
                :style="
                    variant === 'vertical'
                        ? { width: `${Math.min(percentComputed,100)}%` }
                        : { height: `${Math.min(percentComputed,100)}%` }
                "
            ></div>
        </div>
    </div>
</template>
<script>
import { priceToEuroString } from '@/lib/helper';

export default {
    methods: { priceToEuroString },
    props: {
        variant: String, // Either 'vertical' or 'horizontal'
        percent: Number, // The given percentage, optional
        current: Number, // The current value for the percentage
        target: Number, // The target value to compare to
        size: String, // Size of the progress bar, e.g., 'small'
        showValues: Boolean, // Whether or not to display current/target values below the bar
        formatter: {
            type: Function,
            default: priceToEuroString,
        },
    },
    computed: {
        progressText() {
            if (this.current > this.target) {
                return `${this.percentComputed}% - Ziel übertroffen! 🚀`;
            }

            // Case 2: If progress is 100% or more
            if (this.percentComputed === 100) {
                return `100% - Ziel erreicht! 🎉`;
            }

            if (this.percentComputed < 40) {
                return `${this.percentComputed}% - Auf dem Weg! 🚗`;
            }

            if (this.percentComputed < 70) {
                return `${this.percentComputed}% - Fast da! 💪️`;
            }

            if (this.percentComputed < 100) {
                return `${this.percentComputed}% - Nur noch ein Stück! 🏁`;
            }

            return `${this.percentComputed}%`;
        },
        percentComputed() {
            if (this.percent !== undefined) {
                return this.percent;
            }
            if (this.current !== undefined && this.target !== undefined && this.target !== 0) {
                return Math.round((this.current / this.target) * 100);
            }
            return 0;
        },
    },
};
</script>
<style lang="scss" scoped>
.ProgressWrap {
    position: relative;
    height: 100%;

    &.is-small {
        .Progress {
            width: 25px;
        }
    }

    &.show-values {
        .Progress {
            cursor: pointer;
        }
    }

    &.is-vertical {
        .ProgressBarWrap {
            height: 25px;
            width: 100%;
            margin-top: 5px;
        }

        .Progress,
        .ProgressFull {
            height: 50px;
            width: 100%;
        }

        &.is-small {
            .Progress,
            .ProgressFull {
                height: 25px;
                width: 100%;
            }
        }
    }

    .Hint {
        margin-top: 0;
    }

    .ProgressBarWrap {
        position: relative;
        width: 25px;
        height: 100%;
        margin-top: 0px;
    }

    .Progress,
    .ProgressFull {
        background-color: var(--color-bg);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50px;
        height: 100%;
        border-radius: 5px;

        &.is-low {
            background-color: var(--color-red);
        }

        &.is-medium {
            background-color: var(--color-yellow-dark);
        }

        &.is-high {
            background-color: var(--color-green);
        }

        &.is-full {
            background-color: #30edadff;
        }
    }

    .Progress {
        transition: all 0.3s ease;
        position: absolute;
    }

    .ProgressValues {
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 700;
        color: var(--color-text-white);
    }
}
</style>
