var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ProgressWrap",
    class: ["is-".concat(_vm.size), "is-".concat(_vm.variant), {
      'show-values': _vm.showValues
    }]
  }, [_vm.showValues ? _c('span', {
    staticClass: "Hint"
  }, [_vm._v(" " + _vm._s(_vm.progressText))]) : _vm._e(), _vm._t("default"), _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.showValues ? {
        triggers: ['click'],
        autoHide: true,
        distance: 12,
        content: "".concat(_vm.percentComputed, "% Von ").concat(_vm.formatter(_vm.target))
      } : null,
      expression: "\n            showValues\n                ? {\n                      triggers: ['click'],\n                      autoHide: true,\n                      distance: 12,\n                      content: `${percentComputed}% Von ${formatter(target)}`,\n                  }\n                : null\n        "
    }],
    staticClass: "ProgressBarWrap"
  }, [_c('div', {
    staticClass: " ProgressFull"
  }), _c('div', {
    staticClass: "Progress",
    class: _vm.percentComputed < 25 ? 'is-low' : _vm.percentComputed < 50 ? 'is-medium' : _vm.percentComputed < 70 ? 'is-high' : 'is-full',
    style: _vm.variant === 'vertical' ? {
      width: "".concat(Math.min(_vm.percentComputed, 100), "%")
    } : {
      height: "".concat(Math.min(_vm.percentComputed, 100), "%")
    }
  })])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }