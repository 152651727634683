<template>
    <div class="Overview" :class="{ 'is-loading': isFetchingData }">
        <div style="font-size: 1rem; margin-bottom: 10px;">
            <Datepicker monthPicker @onChange="handleDateChange" :startDate="startDate" />
        </div>
        <ChartV2
            v-if="data.length"
            halfHeight
            color="green"
            type="bar"
            :values="data"
            title=" "
            @onLeave="activeRow = null"
        >
            <RevenueSummary
                :currentRevenue="monthlyIncome"
                :comparisonRevenue="lastMonthIncome"
                :currentStartDate="startDate"
                :comparisonStartDate="subMonths(startDate, 1)"
            />
        </ChartV2>
        <div class="OverviewCards">
            <Card variant="info" v-if="widgets.find(widget => widget.type === 'targetIncome')">
                <IconCog
                    style="color:var(--color-text-blue);position: absolute;top: 10px;right: 10px;z-index: 1; cursor: pointer;"
                    @click.native="handleOnSettingsClick"
                />
                <Progress
                    size="small"
                    variant="vertical"
                    :showValues="true"
                    :target="targetIncome * 100"
                    :current="monthlyIncome"
                >
                    <h2>
                        {{ priceToEuroString(monthlyIncome) }}
                    </h2>
                </Progress>
            </Card>

            <Card variant="info">
                <p>Bester Fahrer: {{ highestIncomeDriver?.name }}</p>
                <h3>
                    {{ priceToEuroString(highestIncomeDriver?.shiftSums?.income) }}
                </h3>
            </Card>

            <Card variant="info">
                <p>Bestes Fahrzeug: {{ highestIncomeCar?.licenseNumber }}</p>
                <h3>
                    {{ priceToEuroString(highestIncomeCar?.shiftSums?.income) }}
                </h3>
            </Card>

            <!--            <Card variant="info">-->
            <!--                <p>Unbenutztes Fahrzeug</p>-->
            <!--                <h3>{{ mostIdleCar?.licenseNumber }}</h3>-->
            <!--                <p v-if="mostIdleCar.daysSinceLastSignal">-->
            <!--                    Keine Daten seit {{ mostIdleCar?.daysSinceLastSignal }} Tage-->
            <!--                </p>-->
            <!--                <p v-else>Immer aktiv</p>-->
            <!--            </Card>-->
            <Card variant="warning" v-if="dueReminders.length">
                <p>Fällige Termine</p>
                <h3>{{ dueReminders?.length }} Einträge</h3>
            </Card>
            <Card variant="warning" v-if="incorrectLogin.length">
                <p>Falschanmeldungen</p>
                <h3>{{ incorrectLogin?.length }} Einträge</h3>
                <Button
                    v-if="incorrectLogin?.length > 0"
                    size="small"
                    :marginTop="2"
                    style="align-self: flex-end"
                    @click.native="handleIncorrectEditClick"
                    >Bearbeiten
                </Button>
            </Card>
        </div>

        <div class="Wrap" v-if="!hasNoData">
            <Card class="col">
                <Row
                    v-if="sortedDrivers.length > 0"
                    variant="minimal"
                    :parentHover="false"
                    :items="{ row: driverHeader }"
                    :key="-1"
                    :index="-1"
                    @onClickColumn="handleDriverHeaderClick"
                    class="Row-Header"
                />
                <Row
                    v-for="(driver, i) in sortedDrivers"
                    variant="minimal"
                    :items="driver"
                    :key="i"
                    :hasBadge="driver.isSelected"
                    :hasClick="true"
                    :index="i"
                    :isActive="driver.isSelected"
                    @onClick="handleDriverClick"
                    @onHover="args => $emit('onRowHover', args)"
                    @onLeave="args => $emit('onRowLeave', args)"
                />
                <p v-if="!sortedDrivers.length">Noch keine Daten vorhanden.</p>
            </Card>
            <Card class="col" v-if="hasReminderFeature && groupedReminders.length">
                <div
                    v-for="(reminders, i) in groupedReminders"
                    :key="i"
                    style="margin-bottom: 20px;"
                >
                    <Row
                        v-if="reminders.items.length > 0"
                        variant="minimal"
                        class="Row-Header"
                        :parentHover="false"
                        :items="{
                            row: reminderHeader.map((h, i) => ({
                                ...h,
                                name: i === 0 ? h.name + ' ' + reminders.entity : h.name,
                            })),
                        }"
                        :key="-1"
                        :index="-1"
                        @onClickColumn="() => {}"
                    />
                    <Row
                        v-for="(reminder, j) in reminders.items"
                        :variant="'minimal'"
                        :hasError="reminder.isDue"
                        :hasBadge="reminder.isDue"
                        :items="reminder"
                        hasClick
                        @onClick="e => handleReminderClick(reminder, e)"
                        :key="j"
                        :index="j"
                    />
                </div>

                <p v-if="!groupedReminders.length">Noch keine Erinnerungen vorhanden.</p>
            </Card>
            <Card class="col">
                <Row
                    v-if="sortedCars.length > 0"
                    variant="minimal"
                    class="Row-Header"
                    :parentHover="false"
                    :items="{ row: carHeader }"
                    :key="-1"
                    :index="-1"
                    @onClickColumn="handleCarHeaderClick"
                />
                <Row
                    v-for="(car, i) in sortedCars"
                    variant="minimal"
                    :items="car"
                    :key="i"
                    :index="i"
                    :hasBadge="car.isSelected"
                    :hasClick="true"
                    :isActive="car.isSelected"
                    @onClick="handleCarClick"
                    @onHover="args => $emit('onRowHover', args)"
                    @onLeave="args => $emit('onRowLeave', args)"
                />
                <p v-if="!sortedCars.length">Noch keine Daten vorhanden.</p>
            </Card>
            <!--            <Card class="col">-->
            <!--                <h3>Höchster Umsatz</h3>-->
            <!--                <Row-->
            <!--                    v-for="(highestIncome, i) in highestIncomeCarAndDriver"-->
            <!--                    variant="minimal"-->
            <!--                    :items="highestIncome"-->
            <!--                    :hasClick="true"-->
            <!--                    :key="i"-->
            <!--                    :index="-1"-->
            <!--                    @onClick="-->
            <!--                        highestIncome.licenseNumber-->
            <!--                            ? handleCarClick({ item: highestIncome })-->
            <!--                            : handleDriverClick({ item: highestIncome })-->
            <!--                    "-->
            <!--                    @onHover="args => $emit('onRowHover', args)"-->
            <!--                    @onLeave="args => $emit('onRowLeave', args)"-->
            <!--                />-->
            <!--                <p v-if="!highestIncomeCarAndDriver.length">Noch keine Daten vorhanden.</p>-->
            <!--            </Card>-->
            <Card class="col col--individual-performance">
                <h3>Km-Schnitt: {{ selectedDriver.name || selectedCar.licenseNumber }}</h3>
                <div class="performance-wrap">
                    <Progress :percent="selectedItemPerformance.percentage" />

                    <div class="performance-values">
                        <div>
                            <span> Gesamt-Km: </span>
                            {{ selectedItem ? distanceToString(selectedItem.totalDistance) : 0 }}
                        </div>
                        <div>
                            <span> Besetzt-Km: </span>
                            {{ selectedItem ? distanceToString(selectedItem.hiredDistance) : 0 }}
                        </div>
                        <div>
                            <span> Besetzt-%:</span>
                            {{
                                selectedItem
                                    ? percentageToString(
                                          selectedItem.hiredDistance /
                                              (selectedItem.totalDistance || 1),
                                      )
                                    : 0
                            }}
                        </div>
                        <div>
                            <span> Schichtzeit:</span>
                            {{ selectedItem ? timeToString(selectedItem.shiftTime) : 0 }}
                        </div>
                        <div>
                            <span> Einnahmen:</span>
                            {{ selectedItem ? priceToEuroString(selectedItem.income) : 0 }}
                        </div>
                        <div>
                            <span> Euro/Km:</span>
                            {{
                                selectedItem
                                    ? '≈' +
                                      (
                                          (selectedItem.income * 10) /
                                          (selectedItem.totalDistance || 1)
                                      ).toFixed(2) +
                                      ' €'
                                    : 0
                            }}
                        </div>
                    </div>
                </div>
            </Card>

            <Card class="col">
                <h3>Durchschnittlicher Umsatz</h3>
                <Row
                    v-for="(averageIncome, i) in averageIncomeCarsAndDrivers"
                    variant="minimal"
                    :items="averageIncome"
                    :key="i"
                    :index="-1"
                    @onHover="args => $emit('onRowHover', args)"
                    @onLeave="args => $emit('onRowLeave', args)"
                />
                <p v-if="!averageIncomeCarsAndDrivers.length">Noch keine Daten vorhanden.</p>
            </Card>

            <Card class="col">
                <h3>Kein Signal über 2+ Tage</h3>
                <Row
                    v-for="(car, i) in carsWithNoPingInLast3Days"
                    variant="minimal"
                    :items="car"
                    :key="i"
                    :index="i"
                    @onHover="args => $emit('onRowHover', args)"
                    @onLeave="args => $emit('onRowLeave', args)"
                />
                <p v-if="!carsWithNoPingInLast3Days.length">Noch keine Daten vorhanden.</p>
            </Card>
            <Card class="col">
                <h3>Keine Daten über 2+ Tage</h3>
                <Row
                    v-for="(car, i) in carsWithNoDataInLast3Days"
                    variant="minimal"
                    :items="car"
                    :key="i"
                    :index="i"
                    @onHover="args => $emit('onRowHover', args)"
                    @onLeave="args => $emit('onRowLeave', args)"
                />
                <p v-if="!carsWithNoDataInLast3Days.length">Noch keine Daten vorhanden.</p>
            </Card>
            <!--            <Card class="col" v-if="incorrectLogin.length">-->
            <!--                <h3 style="max-width: 50%">Fehlerhafte Daten</h3>-->
            <!--                <Button-->
            <!--                    v-if="incorrectLogin.length > 0"-->
            <!--                    size="small"-->
            <!--                    style="position: absolute;top: 10px;right: 0px;z-index: 1; cursor: pointer;"-->
            <!--                    @click.native="handleIncorrectEditClick"-->
            <!--                    >Bearbeiten-->
            <!--                </Button>-->
            <!--                <Row-->
            <!--                    v-for="(driver, i) in incorrectLogin"-->
            <!--                    variant="minimal"-->
            <!--                    :items="driver"-->
            <!--                    :key="i"-->
            <!--                />-->
            <!--                <p v-if="!incorrectLogin.length">Noch keine Daten vorhanden.</p>-->
            <!--            </Card>-->
            <Card class="col">
                <h3>Technischer Support</h3>
                <p>
                    <b>Telefon</b>:<a href="tel:+4917672905450"> 0176 7290 5450 <br /></a>
                    <b>Dienstag</b>: 14-16 Uhr <br />
                    <b>Donnerstag</b>: 16-19 Uhr <br />
                </p>
                <br />
                <hr />
                <br />
                <p>
                    <b>Email</b>: <a href="mailto:info@taxifusion.de">info@taxifusion.de</a>
                    <br />
                    <span class="Hint">Antwort innerhalb 2 Werktagen</span>
                </p>
            </Card>
        </div>
        <EmptyState v-else />
        <Modal
            title="Umsatzziel ändern"
            :show="revenueGoalModal"
            :action="{ text: 'Speichern', color: 'green', callback: handleSaveRevenueGoal }"
            :cancel="{ text: 'Abbrechen' }"
            :isLoading="isSavingRevenueGoal"
            @onModalClose="handleModalClose"
        >
            <p>
                Bitte geben Sie den neuen Umsatz ein, den Sie erreichen möchten (in Euro) pro Monat.
            </p>
            <br />
            <br />
            <label>Umsatzziel (in Euro)</label>
            <Input
                type="number"
                keyName="targetIncome"
                :value="targetIncome"
                @onKeyPress="handleTargetIncomeChange"
            />
            <br />
        </Modal>
        <NewsModal
            :show="news.length > 0"
            :title="news[0]?.title"
            :uuid="news[0]?.uuid"
            :needsConfirmation="!!news[0]?.needsConfirmation"
            :message="news[0]?.text"
            :onHasConfirmed="handleNewsModelConfirm"
            :isLoading="isNewsModalLoading"
            @onClose="handleNewsModalClose"
        />
    </div>
</template>

<script type="text/javascript">
import { fakerDE } from '@faker-js/faker';
import { fakerTR } from '@faker-js/faker';
import { fakerAR } from '@faker-js/faker';

import { mapGetters } from 'vuex';
import axios from 'axios';
import NewsModal from '@/components/NewsModal';

import { addMonths, format, getMonth, isPast, startOfMonth, subMonths } from 'date-fns';
import { de } from 'date-fns/locale';
import Modal from '@/components/widgets/Modal';
import Headline from '@/components/Headline';
import Row from '@/components/Row';
import Card from '@/components/Card';
import ChartV2 from '@/components/widgets/ChartV2';
import Progress from '@/components/widgets/Progress';
import Datepicker from '@/components/widgets/Datepicker';
import Input from '@/components/widgets/Input';
import Button from '@/components/widgets/Button';

import {
    priceToEuroString,
    round2d,
    distanceToString,
    percentageToString,
    timeToString,
    euro,
} from '@/lib/helper';
import IconCog from '@/components/icons/IconCog.vue';
import EmptyState from '@/components/EmptyState.vue';
import { topicToLabel } from '../Cars/Car/components/services';
import RevenueSummary from '@/components/RevenueChartInfo.vue';

export default {
    name: 'Overview',
    components: {
        RevenueSummary,
        Button,
        Datepicker,
        Input,
        Modal,
        Headline,
        Row,
        ChartV2,
        Card,
        Progress,
        IconCog,
        EmptyState,
        NewsModal,
    },
    data: () => {
        return {
            news: [],
            isNewsModalLoading: false,
            priceToEuroString,
            timeToString,
            distanceToString,
            percentageToString,
            targetIncome: 7000000,
            revenueGoal: 7000000,
            revenueGoalModal: false,
            isSavingRevenueGoal: false,
            cars: [],
            drivers: [],
            data: [],
            carData: [],
            reminders: [],
            startDate: new Date(),
            selectedItem: {},
            isFetchingData: false,
            widgets: [],
            lastMonthIncome: 0,
            driverSortState: {
                column: 'Einnahmen',
                ascending: false,
            },
            carSortState: {
                column: 'Einnahmen',
                ascending: false,
            },
        };
    },
    computed: {
        ...mapGetters(['contactPerson', 'modules']),
        hasNoData() {
            const nonSystemDrivers = this.drivers.filter(driver => !driver.isSystemDriver);
            return this.cars.length === 0 && nonSystemDrivers.length === 0;
        },
        hasReminderFeature() {
            return this.modules.includes('reminder');
        },
        driverHeader() {
            return [
                { name: 'Fahrer', value: null },
                { name: 'Einnahmen', value: null },
                { name: 'EURO/KM', value: null },
            ].map(header => {
                let indicator = '';
                if (this.driverSortState.column === header.name) {
                    indicator = this.driverSortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        carHeader() {
            return [
                { name: 'Fahrzeug', value: null },
                { name: 'Einnahmen', value: null },
                { name: 'EURO/KM', value: null },
            ].map(header => {
                let indicator = '';
                if (this.carSortState.column === header.name) {
                    indicator = this.carSortState.ascending ? ' ▲' : ' ▼';
                }
                return { ...header, name: header.name + indicator };
            });
        },
        remainingIncome() {
            return Math.max(this.targetIncome * 100 - this.monthlyIncome, 0);
        },
        selectedItemPerformance() {
            const performance = Number(
                ((this.selectedItem.income * 10) / (this.selectedItem.totalDistance || 1)).toFixed(
                    2,
                ),
            );
            const percentage = Math.min((performance / 2) * 100, 100);
            if (performance > 1.39) {
                // super green
                return { performance, percentage };
            } else if (1.39 >= performance && performance > 1.19) {
                // green
                return { performance, percentage };
            } else if (1.19 >= performance && performance > 0.9) {
                // yellow
                return { performance, percentage: 40 };
            } else if (0.9 >= performance) {
                // red
                return { performance, percentage: 20 };
            }
            return { performance, percentage: 0 };
        },

        reminderHeader() {
            return [
                { name: 'Erinnerung', value: null },
                {
                    name: '',
                    value: null,
                    props: {
                        style: 'align-items: flex-end;',
                    },
                },
            ];
        },
        dueReminders() {
            const dueItems = this.groupedReminders.reduce((acc, arr = []) => {
                acc.push(...arr.items.filter(r => r.isDue));
                return acc;
            }, []);
            return dueItems;
        },
        groupedReminders() {
            const entityTranslations = {
                Driver: 'Fahrer',
                Car: 'Fahrzeug',
            };

            // create a map of reminders by entity
            const remindersByEntity = this.reminders.reduce((acc, reminder) => {
                if (!acc[reminder.entity]) {
                    acc[reminder.entity] = [];
                }
                acc[reminder.entity].push(reminder);
                return acc;
            }, {});

            const reminders = Object.keys(remindersByEntity).map(entity => {
                const reminders = remindersByEntity[entity];

                const items = reminders.map(reminder => {
                    const deadline = new Date(reminder.deadline * 1000);
                    const type = reminder.type;
                    const formattedDeadline =
                        type === 'dateTime'
                            ? `Am ${format(deadline, 'dd.MM.yyyy', { locale: de })}`
                            : `In ${distanceToString(
                                  Math.abs(reminder.currentValue - reminder.deadline),
                                  'km',
                              )}`;

                    const isDue =
                        type === 'dateTime'
                            ? isPast(deadline)
                            : Math.abs(reminder.currentValue - reminder.deadline) <= 0;
                    const driver = this.drivers.find(d => d.uuid === reminder.entityUuid);
                    const car = this.carData.find(c => c.uuid == reminder.entityUuid);
                    const licenseNumber = car?.licenseNumber;
                    return {
                        index: reminder.entityUuid,
                        uuid: reminder.entityUuid,
                        isSelected: false,
                        isDue,
                        row: [
                            {
                                value:
                                    topicToLabel[reminder.topic] +
                                    ' <br>' +
                                    (driver?.name || licenseNumber || car?.vin || ''),
                                name: '',
                            },
                            {
                                value: formattedDeadline,
                                name: '',
                                props: {
                                    style: 'align-items: flex-end;',
                                },
                            },
                            {
                                name: '',
                                value: `<span style="padding:1px; font-size:1rem;cursor:pointer;"><i class="ButtonSilence ri-notification-off-line"></i></span>`,
                                props: {
                                    dayPicker: true,
                                    keyName: 'isVisible',
                                    variant: 'inline',
                                    size: 'small',
                                    style:
                                        'flex:none; height:100%; justify-content:flex-end; align-items:center; text-decoration:none; cursor:pointer;',
                                    tooltip: 'Eintrag Stummschalten',
                                    startDate: new Date(reminder.deadline * 1000),
                                    endDate: new Date(reminder.deadline * 1000),
                                    onDateChange: range =>
                                        this.handleReminderMute({ reminder, range }),
                                },
                                component: 'Datepicker',
                            },
                        ],
                    };
                });
                return {
                    entity: entityTranslations[entity] || entity,
                    items,
                };
            });
            return reminders;
        },

        sortedCars() {
            return [...this.cars]
                .sort((a, b) => {
                    const aEntry = a.row.find(e => e.name === this.carSortState.column);
                    const aVal = aEntry?.rawValue;
                    const bEntry = b.row.find(e => e.name === this.carSortState.column);
                    const bVal = bEntry?.rawValue;
                    if (this.carSortState.ascending) {
                        return aVal > bVal ? 1 : -1;
                    }
                    return aVal < bVal ? 1 : -1;
                })
                .filter(d => d.shiftSums.income > 0);
        },
        sortedDrivers() {
            return [...this.drivers]
                .sort((a, b) => {
                    const aEntry = a.row.find(e => e.name === this.driverSortState.column);
                    const aVal = aEntry?.rawValue;
                    const bEntry = b.row.find(e => e.name === this.driverSortState.column);
                    const bVal = bEntry?.rawValue;
                    if (this.driverSortState.ascending) {
                        return aVal > bVal ? 1 : -1;
                    }
                    return aVal < bVal ? 1 : -1;
                })
                .filter(d => d.shiftSums.income > 0);
        },

        selectedCar() {
            return this.cars.find(car => car.isSelected) || {};
        },
        selectedDriver() {
            return (
                {
                    ...this.drivers.find(driver => driver.isSelected),
                    // name: this.handleRandomName(),
                } || {}
            );
        },
        carsWithNoDataInLast3Days() {
            const now = new Date();

            const carsWithoutData = this.cars
                .filter(car => {
                    if (!car.lastDataAt) return false;
                    const lastData = new Date(car.lastDataAt);
                    const now = new Date();
                    const diff = now.getTime() - lastData.getTime();
                    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                    return diffDays > 3;
                })
                .map(car => {
                    const lastDataDate = new Date(car.lastDataAt);
                    const daysSinceLastSignal = Math.ceil(
                        (now - lastDataDate) / (1000 * 60 * 60 * 24), // Calculate days
                    );

                    return {
                        ...car,
                        daysSinceLastSignal,
                        row: [
                            {
                                name: 'Fahrzeug',
                                value: car.licenseNumber,
                            },
                            {
                                name: 'Letztes Signal',
                                value: format(new Date(car.lastDataAt), 'dd.MM.yyyy HH:mm', {
                                    locale: de,
                                }),
                            },
                        ],
                    };
                })
                .sort((a, b) => b.daysSinceLastSignal - a.daysSinceLastSignal); // Sort by most idle

            return carsWithoutData;
        },
        mostIdleCar() {
            return this.carsWithNoDataInLast3Days.length > 0
                ? this.carsWithNoDataInLast3Days[0]
                : {};
        },

        carsWithNoPingInLast3Days() {
            const carsWithoutPing = this.cars
                .filter(car => {
                    if (!car.lastPingAt) return false;
                    const lastPing = new Date(car.lastPingAt);
                    const now = new Date();
                    const diff = now.getTime() - lastPing.getTime();
                    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                    return diffDays > 3;
                })
                .map(car => {
                    return {
                        ...car,
                        row: [
                            {
                                name: 'Fahrzeug',
                                value: car.licenseNumber,
                            },
                            {
                                name: 'Letztes Signal',
                                value: format(new Date(car.lastPingAt), 'dd.MM.yyyy HH:mm', {
                                    locale: de,
                                }),
                            },
                        ],
                    };
                })
                .sort((a, b) => {
                    const aEntry = a.row.find(e => e.name === 'Letztes Signal');
                    const aVal = aEntry?.value;
                    const bEntry = b.row.find(e => e.name === 'Letztes Signal');
                    const bVal = bEntry?.value;
                    return aVal > bVal ? 1 : -1;
                });
            return carsWithoutPing;
        },
        incorrectLogin() {
            if (!this.drivers?.length) return [];
            const incorrectDrivers = this.drivers
                .filter(driver => {
                    const drivers = JSON.parse(localStorage.getItem('drivers'));
                    if (!drivers) return false;
                    const currentDriver = drivers.find(d => d.id === driver.driverNumber);
                    return (
                        driver.driverNumber == driver.name
                        // (!currentDriver?.isVisible && driver.shiftSums.income > 0)
                    );
                })
                .map(driver => {
                    return {
                        ...driver,
                        row: [
                            {
                                name: 'Fahrer',
                                value: this.handleRandomName(),
                                value: driver.name,
                            },
                        ],
                    };
                });
            return incorrectDrivers || [];
        },
        monthlyIncome() {
            return this.data.reduce((acc, item) => acc + item.value, 0);
        },
        highestIncomeCar() {
            const car = this.cars.reduce(
                (prev, current) =>
                    prev?.shiftSums?.income > current?.shiftSums?.income ? prev : current,
                {},
            );

            return car?.shiftSums?.income > 0 ? car : { ...car, licenseNumber: 'N/A' };
        },
        highestIncomeDriver() {
            const driver = this.drivers.reduce(
                (prev, current) =>
                    prev?.shiftSums?.income > current?.shiftSums?.income ? prev : current,
                {},
            );

            return driver?.shiftSums?.income > 0 ? driver : { ...driver, name: 'N/A' };
        },
        averageIncomeActiveCars() {
            return (
                this.cars.reduce((acc, car) => acc + car?.shiftSums?.income || 0, 0) /
                this.activeCars.length
            );
        },
        averageIncomeAllCars() {
            return (
                this.cars.reduce((acc, car) => acc + car?.shiftSums?.income || 0, 0) /
                this.cars.length
            );
        },
        averageIncomeDrivers() {
            const filteredDrivers = this.drivers.filter(
                driver =>
                    !driver.isSystemDriver && (driver.isVisible || driver.shiftSums.income > 0),
            );
            return (
                filteredDrivers.reduce((acc, driver) => acc + (driver?.shiftSums?.income || 0), 0) /
                (filteredDrivers.length || 1)
            );
        },
        averageIncomeDriversWithIncome() {
            const filteredDrivers = this.drivers.filter(
                driver => !driver.isSystemDriver && driver.shiftSums.income > 0,
            );
            console.log(
                filteredDrivers.reduce((acc, driver) => acc + (driver?.shiftSums?.income || 0), 0),
            );
            return (
                filteredDrivers.reduce((acc, driver) => acc + (driver?.shiftSums?.income || 0), 0) /
                (filteredDrivers.length || 1)
            );
        },
        averageIncomeCarsAndDrivers() {
            const countedVisibleDrivers = this.drivers.filter(
                driver =>
                    !driver.isSystemDriver && (driver.isVisible || driver.shiftSums.income > 0),
            );

            const countedDriversWithRevenue = this.drivers.filter(
                driver => !driver.isSystemDriver && driver.shiftSums.income > 0,
            );

            return [
                {
                    row: [
                        {
                            name: 'Durschnitt',
                            value: `Fahrer (${countedVisibleDrivers.length})`,
                        },
                        {
                            name: 'Umsatz',
                            value: priceToEuroString(this.averageIncomeDrivers),
                        },
                    ],
                },
                {
                    row: [
                        {
                            name: 'Durschnitt',
                            value: `Fahrer mit Umsatz (${countedDriversWithRevenue.length})`,
                        },
                        {
                            name: 'Umsatz',
                            value: priceToEuroString(this.averageIncomeDriversWithIncome),
                        },
                    ],
                },
                {
                    row: [
                        {
                            name: 'Durschnitt',
                            value: `Alle Fahrzeuge (${this.cars.length})`,
                        },
                        {
                            name: 'Umsatz',
                            value: priceToEuroString(this.averageIncomeAllCars),
                        },
                    ],
                },
                {
                    row: [
                        {
                            name: 'Durschnitt',
                            value: `Fahrzeuge mit Umsatz (${this.activeCars.length})`,
                        },
                        {
                            name: 'Umsatz',
                            value: priceToEuroString(this.averageIncomeActiveCars),
                        },
                    ],
                },
            ];
        },
        activeCars() {
            return this.cars.filter(car => car.isActive);
        },
    },
    methods: {
        subMonths,
        handleRandomName() {
            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
                2: `${fakerAR.person.firstName()} ${fakerAR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 3)];
        },
        handleNewsModalClose() {
            this.news.shift();
        },
        async handleNewsModelConfirm() {
            this.isNewsModalLoading = true;

            try {
                await axios.post(
                    `${process.env.VUE_APP_API_BASE_URL}/cpanel/news/${this.news[0]?.uuid}`,
                    {},
                    {
                        withCredentials: true,
                    },
                );
                this.$toasted.show('Nachricht wurde bestätigt.', {
                    type: 'success',
                });
            } catch (error) {
                console.error(error);
                this.$toasted.show('Ein Fehler ist aufgetreten.', {
                    type: 'error',
                });
            } finally {
                this.isNewsModalLoading = false;
            }
        },
        async handleReminderClick(rowItem, e) {
            const { event } = e;
            const target = event.target;
            if (target.classList.contains('ButtonSilence')) {
                return;
            }
            const car = this.carData.find(car => car.uuid === rowItem.uuid);
            const driver = this.drivers.find(driver => driver.uuid === rowItem.uuid);
            if (car) {
                this.$router.push({
                    path: '/cars/' + car.uuid,
                });
            } else if (driver) {
                this.$router.push({
                    path: '/employees/',
                    query: { uuid: driver.uuid },
                });
            }
        },
        async handleReminderMute({ reminder, range }) {
            const date = range.from;
            try {
                const response = await axios.patch(
                    `${process.env.VUE_APP_API_BASE_URL}/cpanel/reminders/${reminder.uuid}`,
                    {
                        dateTime: date.toISOString(),
                    },
                    {
                        withCredentials: true,
                    },
                );

                this.$toasted.show(
                    `Erinnerung wurde bis zum ${format(date, 'dd.MM.yyyy')} stummgeschaltet.`,
                    {
                        type: 'success',
                    },
                );

                this.handleQuery();
            } catch (error) {}
        },
        handleCarHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.carSortState.column === columnName) {
                this.carSortState.ascending = !this.carSortState.ascending;
            } else {
                this.carSortState.column = columnName;
                this.carSortState.ascending = true;
            }
        },
        handleDriverHeaderClick(column) {
            const columnName = column.name
                .replace('▲', '')
                .replace('▼', '')
                .trim();
            if (this.driverSortState.column === columnName) {
                this.driverSortState.ascending = !this.driverSortState.ascending;
            } else {
                this.driverSortState.column = columnName;
                this.driverSortState.ascending = true;
            }
        },
        handleDateChange(date) {
            this.startDate = date.from;
            this.handleQuery();
        },
        setActiveBar(item) {
            this.drivers = this.drivers.map(driver => {
                return {
                    ...driver,
                    isSelected:
                        driver.driverNumber === item.driverNumber ? !driver.isSelected : false,
                };
            });
            this.cars = this.cars.map(car => {
                return {
                    ...car,
                    isSelected: car.licenseNumber === item.licenseNumber ? !car.isSelected : false,
                };
            });
        },
        handleIncorrectEditClick() {
            this.$router.push({
                name: 'shifts',
                query: { incorrect: true },
            });
        },
        handleDriverClick({ item }) {
            const activeDriver = this.drivers.find(driver => driver.isSelected);
            const selectedDriver = this.drivers.find(
                driver => driver.driverNumber === item.driverNumber,
            );
            if (selectedDriver.driverNumber == activeDriver?.driverNumber) {
                this.$router.push({
                    name: 'shifts',
                    query: {
                        driverNumber: selectedDriver.driverNumber,
                        startDate: startOfMonth(this.startDate),
                    },
                });
            } else {
                this.setActiveBar(item);
                this.selectedItem = selectedDriver.shiftSums;
            }
        },
        handleCarClick({ item }) {
            const activeCar = this.cars.find(car => car.isSelected);
            const selectedCar = this.cars.find(car => car?.licenseNumber === item.licenseNumber);
            if (selectedCar.licenseNumber == activeCar?.licenseNumber) {
                this.$router.push({
                    name: 'shifts',
                    query: {
                        licenseNumber: selectedCar.licenseNumber,
                        startDate: startOfMonth(this.startDate),
                    },
                });
            } else {
                this.setActiveBar(item);
                this.selectedItem = selectedCar.shiftSums;
            }
        },
        handleDownload(type) {},
        handleOnSettingsClick() {
            this.revenueGoalModal = true;
        },
        handleModalClose() {
            this.revenueGoalModal = false;
        },
        async handleSaveRevenueGoal() {
            this.isSavingRevenueGoal = true;
            const uuid = this.widgets.find(widget => widget.type === 'targetIncome').uuid;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/widgets/${uuid}`;
                const result = await axios.patch(
                    url,
                    {
                        name: 'Erreichter Umsatz',
                        type: 'targetIncome',
                        state: {
                            targetIncome: Number(this.targetIncome),
                        },
                    },
                    {
                        withCredentials: true,
                    },
                );
                this.$toasted.show('Umsatzziel wurde erfolgreich gespeichert.', {
                    type: 'success',
                });
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isSavingRevenueGoal = false;
                this.revenueGoalModal = false;
            }
        },
        handleTargetIncomeChange({ targetIncome }) {
            this.targetIncome = targetIncome;
        },
        async handleGetMonth({ year: givenYear, month: givenMonth }) {
            try {
                const year = givenYear || format(this.startDate, 'yyyy');
                const month = givenMonth || format(this.startDate, 'MM');
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/dashboard/${year}/${month}`;
                const result = await axios.get(url, {
                    withCredentials: true,
                });
                return result;
            } catch (e) {
                throw e;
            }
        },
        async handleQuery() {
            this.isFetchingData = true;
            try {
                const year = format(this.startDate, 'yyyy');
                const month = format(this.startDate, 'MM');
                const resultPromise = this.handleGetMonth({ year, month });

                const comparisonYear = format(subMonths(this.startDate, 1), 'yyyy');
                const comparisonMonth = format(subMonths(this.startDate, 1), 'MM');
                const comparisonResultPromise = this.handleGetMonth({
                    year: comparisonYear,
                    month: comparisonMonth,
                });

                const [result, comparisonResult] = await Promise.all([
                    resultPromise,
                    comparisonResultPromise,
                ]);
                this.lastMonthIncome = comparisonResult?.data?.dailyIncomes.reduce((acc, item) => {
                    const currentDateEquivalent = addMonths(new Date(item.date), 1);
                    if (isPast(currentDateEquivalent)) {
                        acc += item.income;
                    }

                    return acc;
                }, 0);
                this.$emit('onFinishLoading');
                this.data = result.data.dailyIncomes.map(item => {
                    return {
                        rawDate: item.date,
                        date: format(new Date(item.date), 'dd.MM', { locale: de }),
                        value: item.income,
                        formatter: priceToEuroString,
                        formattedValue: priceToEuroString(item.income),
                        formattedDate: format(new Date(item.date), 'dd MMM yyyy', { locale: de }),
                    };
                });
                this.news = result.data?.news;

                this.widgets = result.data.widgets;
                this.targetIncome = result.data.widgets.find(
                    w => w.type === 'targetIncome',
                ).state.targetIncome;
                this.reminders = result.data.reminders;

                this.carData = result.data.cars;

                this.cars = result.data.licenseNumbers.map(car => {
                    return {
                        ...car,
                        row: [
                            {
                                name: 'Fahrzeug',
                                // replace after B- the first letter with 0
                                // value: `B-${car.licenseNumber.split('B-')[1].replace(/[a-z]/i, 0)}`,
                                value: car.licenseNumber,
                                rawValue: car.licenseNumber,
                            },
                            {
                                name: 'Einnahmen',
                                value: priceToEuroString(car.shiftSums.income),
                                rawValue: car.shiftSums.income,
                            },
                            {
                                name: 'EURO/KM',
                                rawValue: car.shiftSums.totalDistance
                                    ? (car.shiftSums.income * 10) / car.shiftSums.totalDistance || 0
                                    : 0,
                                value:
                                    '≈' +
                                    (car.shiftSums.totalDistance
                                        ? (car.shiftSums.income * 10) /
                                              car.shiftSums.totalDistance || 0
                                        : 0
                                    ).toFixed(2),
                            },
                        ],
                    };
                });
                this.drivers = result.data.drivers.map((driver, i) => {
                    return {
                        ...driver,
                        isSelected: driver.driverNumber == this.highestIncomeDriver.driverNumber,
                        row: [
                            {
                                name: 'Fahrer',
                                value: this.handleRandomName(),
                                value: driver.name,
                                rawValue: driver.name,
                            },
                            {
                                name: 'Einnahmen',
                                value: priceToEuroString(driver.shiftSums.income),
                                rawValue: driver.shiftSums.income,
                            },
                            {
                                name: 'EURO/KM',
                                value: driver.shiftSums.totalDistance
                                    ? '≈' +
                                      (
                                          (driver.shiftSums.income * 10) /
                                              driver.shiftSums.totalDistance || 0
                                      ).toFixed(2)
                                    : '0',
                                rawValue: driver.shiftSums.totalDistance
                                    ? (driver.shiftSums.income * 10) /
                                      driver.shiftSums.totalDistance
                                    : 0,
                            },
                        ],
                    };
                });
                this.selectedItem = this.highestIncomeDriver.shiftSums;
            } catch (error) {
                console.log({ error });
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    async mounted() {
        setTimeout(async () => {
            await this.handleQuery();
        }, 100);
    },
};
</script>

<style lang="scss">
.Overview {
    @extend %page;
    @extend %contentWrapper;

    .Wrap {
        margin-top: 0px;
        display: grid;
        gap: 4px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        // align-items: flex-start;

        @media (max-width: 1300px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (max-width: 1130px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 930px) {
            grid-template-columns: repeat(auto-fill, 100%);
        }
    }

    .Card {
        &:not(.is-info):not(.is-warning) {
            padding: 0px 5px 10px;
            max-height: 346px;
            height: 100%;
            min-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;

            > :first-child {
                padding-top: 10px;
            }

            h3 {
                font-weight: 700;
                margin-bottom: 15px;
                margin-left: 12px;
            }
        }

        @media (max-width: 930px) {
            max-height: 100%;
        }
    }

    .col {
        color: var(--color-text-blue);
        position: relative;

        p {
            margin-left: 15px;
        }

        h3 {
            top: -15px;
            -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
            font-size: 1rem;
            padding: 4px 30px 5px 15px;
            width: 120%;
            background-color: var(--color-white);
            z-index: 1;
        }
    }

    .col--individual-performance {
        .performance-wrap {
            margin-left: 12px;
            display: flex;
            column-gap: 10px;
            // height: 100%;
            height: 260px;
            position: relative;
        }

        .performance-values {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            // width: 100%;
            height: 260px;
            margin-left: 30px;
            line-height: 1.5;
            font-size: 0.75rem;
            font-weight: 700;

            span {
                font-weight: 400;
                // font-size: 0.9rem;
            }
        }
    }

    .col--support {
        padding: 20px;
        box-shadow: none;
        background-color: transparent;
        color: var(--color-text-black);

        a {
            text-decoration: underline;
        }
    }

    .BubbleChart-Wrap {
        display: grid;
    }
}
</style>
